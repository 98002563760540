import React from 'react';

import { Wrapper, TableWrapper } from './FeeTable.css';

const FeeTable = () => (
  <Wrapper className="mt-4">
    <h2 className="text-center mb-4">Fees</h2>
    <TableWrapper className="table mb-0">
      <thead>
        <tr>
          <th scope="col">Price from</th>
          <th scope="col">Price to</th>
          <th scope="col">Buyer fee + VAT</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>£10,000</th>
          <td>£19,999</td>
          <td>£300</td>
        </tr>
        <tr>
          <th>£20,000</th>
          <td>£29,999</td>
          <td>£350</td>
        </tr>
        <tr>
          <th>£30,000</th>
          <td>£39,999</td>
          <td>£400</td>
        </tr>
        <tr>
          <th>£40,000</th>
          <td>£49,999</td>
          <td>£450</td>
        </tr>
        <tr>
          <th>£50,000</th>
          <td>£74,999</td>
          <td>£500</td>
        </tr>
        <tr>
          <th>£75,000</th>
          <td>£99,999</td>
          <td>£750</td>
        </tr>
        <tr>
          <th>£100,000</th>
          <td>£150,999</td>
          <td>£1000</td>
        </tr>
        <tr>
          <th>£150,000</th>
          <td>Not limit</td>
          <td>£2000</td>
        </tr>
      </tbody>
    </TableWrapper>
  </Wrapper>
);

export default FeeTable;
