import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid #e9e9e9;
  background-color: #fff;
  padding: 30px 10px;
  h2 {
    font-size: 1.2rem;
  }
`;

export const TableWrapper = styled.table`
  font-size: 0.8rem;
  thead {
    th {
      border-top: 0px !important;
      border-bottom: 1px !important;
    }
  }
`;
