import React, { Component } from 'react';
import Img from 'react-fix-image-orientation';
import LazyLoad from 'react-lazyload';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import StickyBox from 'react-sticky-box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faGasPump,
  faCogs,
  faCalendarDay,
  faPhone,
  faInfoCircle,
  faCarCrash,
  faFileAlt,
  faTools,
  faPoundSign,
  faFillDrip,
  faMailBulk,
  faStoreAlt,
} from '@fortawesome/free-solid-svg-icons';

import Carousel from 'components/carousel/carousel';
import CarouselFull from 'components/carousel/carouselFull';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import FeeTable from 'components/FeeTable';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NumberPlate = styled.div`
  font-family: 'UKNumberPlate';
  -webkit-appearance: none;
  width: auto;
  background-color: #ffeb00;
  border-style: none;
  border-color: #ffeb00;
  box-shadow: none;
  font-size: 30px;
  height: auto;
  text-align: center;
  padding: 2px 25px;
  border-radius: 8px;
  transition-duration: 0.15s;
  transition-property: box-shadow;
  transition-timing-function: ease-in-out;
  display: inline-block;
`;

const SideBarDetail = styled.div`
  h1 {
    font-size: 1.5rem;
    text-transform: capitalize;
  }
  h2 {
    font-size: 1rem;
  }
  p {
    text-transform: capitalize;
    font-weight: bold;
  }
  .icon {
    min-width: 40px;
    svg {
      font-size: 1.6rem;
      color: #2cabde;
    }
  }
  .call-btn {
    background-color: #2cabde;
    font-size: 1.4rem;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    &:hover {
      background: #1a789e;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    }
    svg {
      margin-right: 20px;
    }
  }
`;

const ImagesToggleBtn = {
  color: '#2CABDE',
  borderColor: '#2CABDE',
  marginTop: '1rem',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
};

class LeadItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClickOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  render() {
    const { data } = this.props;
    const lead = data.nodeLead;

    const images = [
      lead.relationships.field_front_driver_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_front_driver_side.localFile
          ? lead.relationships.field_front_driver_side.localFile.childImageSharp
              .fixed
          : undefined
        : undefined,
      lead.relationships.field_back_driver_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_back_driver_side.localFile
          ? lead.relationships.field_back_driver_side.localFile.childImageSharp
              .fixed
          : undefined
        : undefined,
      lead.relationships.field_front_passenger_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_front_passenger_side.localFile
          ? lead.relationships.field_front_passenger_side.localFile
              .childImageSharp.fixed
          : undefined
        : undefined,
      lead.relationships.field_back_passenger_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_back_passenger_side.localFile
          ? lead.relationships.field_back_passenger_side.localFile
              .childImageSharp.fixed
          : undefined
        : undefined,
      lead.relationships.field_front_seats.filename != 'CarHolderImage2019.jpg'
        ? lead.relationships.field_front_seats.localFile
          ? lead.relationships.field_front_seats.localFile.childImageSharp.fixed
          : undefined
        : undefined,
      lead.relationships.field_rear_seats.filename != 'CarHolderImage2019.jpg'
        ? lead.relationships.field_rear_seats.localFile
          ? lead.relationships.field_rear_seats.localFile.childImageSharp.fixed
          : undefined
        : undefined,
      lead.relationships.field_dashboard.filename != 'CarHolderImage2019.jpg'
        ? lead.relationships.field_dashboard.localFile
          ? lead.relationships.field_dashboard.localFile.childImageSharp.fixed
          : undefined
        : undefined,
      lead.relationships.field_boot_interior.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_boot_interior.localFile
          ? lead.relationships.field_boot_interior.localFile.childImageSharp
              .fixed
          : undefined
        : undefined,
      lead.relationships.field_wheel_front_driver_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_wheel_front_driver_side.localFile
          ? lead.relationships.field_wheel_front_driver_side.localFile
              .childImageSharp.fixed
          : undefined
        : undefined,
      lead.relationships.field_wheel_rear_driver_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_wheel_rear_driver_side.localFile
          ? lead.relationships.field_wheel_rear_driver_side.localFile
              .childImageSharp.fixed
          : undefined
        : undefined,
      lead.relationships.field_wheel_front_passenger_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_wheel_front_passenger_side.localFile
          ? lead.relationships.field_wheel_front_passenger_side.localFile
              .childImageSharp.fixed
          : undefined
        : undefined,
      lead.relationships.field_wheel_rear_passenger_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_wheel_rear_passenger_side.localFile
          ? lead.relationships.field_wheel_rear_passenger_side.localFile
              .childImageSharp.fixed
          : undefined
        : undefined,
    ].filter(x => x !== undefined);

    const imagesFull = [
      lead.relationships.field_front_driver_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_front_driver_side.localFile
          ? lead.relationships.field_front_driver_side.localFile.childImageSharp
              .fluid
          : undefined
        : undefined,
      lead.relationships.field_back_driver_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_back_driver_side.localFile
          ? lead.relationships.field_back_driver_side.localFile.childImageSharp
              .fluid
          : undefined
        : undefined,
      lead.relationships.field_front_passenger_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_front_passenger_side.localFile
          ? lead.relationships.field_front_passenger_side.localFile
              .childImageSharp.fluid
          : undefined
        : undefined,
      lead.relationships.field_back_passenger_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_back_passenger_side.localFile
          ? lead.relationships.field_back_passenger_side.localFile
              .childImageSharp.fluid
          : undefined
        : undefined,
      lead.relationships.field_front_seats.filename != 'CarHolderImage2019.jpg'
        ? lead.relationships.field_front_seats.localFile
          ? lead.relationships.field_front_seats.localFile.childImageSharp.fluid
          : undefined
        : undefined,
      lead.relationships.field_rear_seats.filename != 'CarHolderImage2019.jpg'
        ? lead.relationships.field_rear_seats.localFile
          ? lead.relationships.field_rear_seats.localFile.childImageSharp.fluid
          : undefined
        : undefined,
      lead.relationships.field_dashboard.filename != 'CarHolderImage2019.jpg'
        ? lead.relationships.field_dashboard.localFile
          ? lead.relationships.field_dashboard.localFile.childImageSharp.fluid
          : undefined
        : undefined,
      lead.relationships.field_boot_interior.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_boot_interior.localFile
          ? lead.relationships.field_boot_interior.localFile.childImageSharp
              .fluid
          : undefined
        : undefined,
      lead.relationships.field_wheel_front_driver_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_wheel_front_driver_side.localFile
          ? lead.relationships.field_wheel_front_driver_side.localFile
              .childImageSharp.fluid
          : undefined
        : undefined,
      lead.relationships.field_wheel_rear_driver_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_wheel_rear_driver_side.localFile
          ? lead.relationships.field_wheel_rear_driver_side.localFile
              .childImageSharp.fluid
          : undefined
        : undefined,
      lead.relationships.field_wheel_front_passenger_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_wheel_front_passenger_side.localFile
          ? lead.relationships.field_wheel_front_passenger_side.localFile
              .childImageSharp.fluid
          : undefined
        : undefined,
      lead.relationships.field_wheel_rear_passenger_side.filename !=
      'CarHolderImage2019.jpg'
        ? lead.relationships.field_wheel_rear_passenger_side.localFile
          ? lead.relationships.field_wheel_rear_passenger_side.localFile
              .childImageSharp.fluid
          : undefined
        : undefined,
    ].filter(x => x !== undefined);

    const formatDate = date => {
      var p = date.split(/\D/g);
      if (p[0] == '1900') {
        return '-';
      } else {
        return [p[2], p[1], p[0]].join('/');
      }
    };

    const { open } = this.state;
    const handleClickOpen = this.handleClickOpen;
    const handleClose = this.handleClose;
    return (
      <Layout>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-12 col-lg-4 offset-0 offset-lg-1 mb-4 mb-lg-5">
              <StickyBox offsetTop={50} offsetBottom={150}>
                <NumberPlate>{lead.field_registration_plate}</NumberPlate>
                <SideBarDetail className="pt-3 pl-0 pl-lg-1">
                  <h1>{lead.relationships.field_make.name.toLowerCase()}</h1>
                  <h2>{lead.field_model}</h2>
                  <div className="pt-4 pl-0 pl-lg-2 row">
                    <div className="mb-3 d-flex align-items-center col-lg-12 col-4">
                      <div className="icon">
                        <FontAwesomeIcon icon={faStoreAlt} />
                      </div>
                      <div>
                        <p className="mb-0">
                          {lead.relationships.uid.relationships.roles[0]
                            .drupal_internal__id == 'customer'
                            ? 'Private seller'
                            : 'Trade seller'}
                        </p>
                      </div>
                    </div>
                    <div className="mb-3 d-flex align-items-center col-lg-12 col-4">
                      <div className="icon">
                        <FontAwesomeIcon icon={faFillDrip} />
                      </div>
                      <div>
                        <p className="mb-0">{lead.field_colour}</p>
                      </div>
                    </div>
                    <div className="mb-3 d-flex align-items-center col-lg-12 col-4">
                      <div className="icon">
                        <FontAwesomeIcon icon={faCalendarDay} />
                      </div>
                      <div>
                        <p className="mb-0">{lead.field_year_of_manufacture}</p>
                      </div>
                    </div>
                    <div className="mb-3 d-flex align-items-center col-lg-12 col-4">
                      <div className="icon">
                        <FontAwesomeIcon icon={faTachometerAlt} />
                      </div>
                      <div>
                        <p className="mb-0">{lead.field_mileage}</p>
                      </div>
                    </div>
                    <div className="mb-3 text-capitalize d-flex align-items-center col-lg-12 col-4">
                      <div className="icon">
                        <FontAwesomeIcon icon={faGasPump} />
                      </div>
                      <div>
                        <p className="mb-0">{lead.field_fuel.toLowerCase()}</p>
                      </div>
                    </div>
                    <div className="mb-3 text-capitalize d-flex align-items-center col-lg-12 col-4">
                      <div className="icon">
                        <FontAwesomeIcon icon={faCogs} />
                      </div>
                      <div>
                        <p className="mb-0">
                          {lead.field_gearbox.toLowerCase()}
                        </p>
                      </div>
                    </div>
                    {lead.field_guide_price > 0 && (
                      <div className="mb-3 text-capitalize d-flex align-items-center col-lg-12 col-4">
                        <div className="icon">
                          <FontAwesomeIcon icon={faPoundSign} />
                        </div>
                        <div>
                          <p className="mb-0">{lead.field_guide_price}</p>
                        </div>
                      </div>
                    )}
                    <div className="w-100 pt-4 text-center col-12">
                      <button className="btn w-100 call-btn text-white">
                        <a className="telActionBtn" href="tel:0207 078 4089">
                          <FontAwesomeIcon
                            className="text-white"
                            icon={faPhone}
                          />
                          0207 078 4089
                        </a>
                      </button>
                    </div>
                    <div className="w-100 pt-2 text-center col-12">
                      <button className="btn w-100 call-btn text-white">
                        <a
                          className="telActionBtn"
                          href="mailto:chris@selliton.co.uk"
                        >
                          <FontAwesomeIcon
                            className="text-white"
                            icon={faMailBulk}
                          />
                          Email us
                        </a>
                      </button>
                    </div>
                    <div className="w-100 pt-2 text-center col-12">
                      <button className="btn w-100 call-btn text-white">
                        <Link className="telActionBtn" to="/signup">
                          Register dealership
                        </Link>
                      </button>
                    </div>
                    <div className="d-none d-lg-block col-12">
                      <FeeTable />
                    </div>
                  </div>
                </SideBarDetail>
              </StickyBox>
            </div>
            <div className="col-12 col-lg-6">
              <div className="slide-container">
                <Carousel images={images} />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickOpen}
                  style={ImagesToggleBtn}
                >
                  Full-screen images
                </Button>
                <Dialog
                  fullScreen
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Transition}
                >
                  <AppBar className="position-relative">
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography variant="h6" className="ml-4">
                        {lead.field_year_of_manufacture}{' '}
                        {lead.relationships.field_make.name} {lead.field_model}{' '}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <CarouselFull images={imagesFull} />
                </Dialog>
              </div>
              <div className="mt-5">
                <h2>
                  <FontAwesomeIcon
                    className="mr-2"
                    style={{ color: '#2cabde' }}
                    icon={faInfoCircle}
                  />{' '}
                  Vehicle Details
                </h2>
                {lead.field_car_description != '&nbsp;' && (
                  <p className="mt-4 mb-4">{lead.field_car_description}</p>
                )}
                <List>
                  {lead.field_basic_details.map(item => (
                    <ListItem key={item}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon className="text-success" />
                      </ListItemIcon>
                      {item == 'Panoramic_roof_sun_roof' ? (
                        <ListItemText primary={'Panoramic roof / sun roof'} />
                      ) : (
                        <ListItemText primary={item.replace(/_/g, ' ')} />
                      )}
                    </ListItem>
                  ))}
                </List>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Vehicle color</p>
                  </div>
                  <div className="col-auto">
                    <p className="mb-0 text-capitalize">
                      {lead.field_colour.toLowerCase()}
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Seat color</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">
                      {lead.field_what_colour_are_the_seats}
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Seat upholstery</p>
                  </div>
                  <div className="col-auto">
                    <p className="mb-0">
                      {lead.field_how_are_they_upholstered}
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Engine capacity</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">{lead.field_engine_capacity}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Number of keys</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">{lead.field_how_many_working_keys}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Car location</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">
                      {lead.relationships.field_car_location
                        ? lead.relationships.field_car_location.name
                        : '-'}
                    </p>
                  </div>
                </div>

                <h2 className="mt-5">
                  <FontAwesomeIcon
                    className="mr-2"
                    style={{ color: '#2cabde' }}
                    icon={faFileAlt}
                  />{' '}
                  Documentation
                </h2>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">VIN</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">PHONE FOR FULL VIN</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">
                      Date of registration
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">
                      {formatDate(lead.field_first_registered)}
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Previous keepers</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">{lead.field_keepers}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Last keeper change</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">
                      {formatDate(lead.field_last_keeper_change)}
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">MOT expiry</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">{formatDate(lead.field_mot_expiry)}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Vehicle financed</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">
                      {lead.field_is_the_vehicle_on_finance}
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Book pack included</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">
                      {lead.field_do_you_have_the_book_pack_}
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">V5C Logbook</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">
                      {lead.field_do_you_have_the_v5c_logboo}
                    </p>
                  </div>
                </div>
                <h2 className="mt-5">
                  <FontAwesomeIcon
                    className="mr-2"
                    style={{ color: '#2cabde' }}
                    icon={faCarCrash}
                  />{' '}
                  Vehicle Condition
                </h2>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Bodywork condition</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">{lead.field_bodywork_condition}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Interior condition</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">{lead.field_interior_condition}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Warning lights</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">
                      {lead.field_warning_lights_on_dashboar}
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">
                      Alloy wheel condition
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">{lead.field_alloy_wheel_condition}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Tyre condition</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">{lead.field_tyre_condition}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <p className="mb-0 font-weight-bold">
                      Further details on condition
                    </p>
                  </div>
                  <div className="col-12">
                    <p className="mb-0 mt-2">
                      {lead.field_details_on_interior_or_ext != '&nbsp;'
                        ? lead.field_details_on_interior_or_ext
                        : 'None'}
                    </p>
                  </div>
                </div>
                <h2 className="mt-5">
                  <FontAwesomeIcon
                    className="mr-2"
                    style={{ color: '#2cabde' }}
                    icon={faTools}
                  />{' '}
                  Service history
                </h2>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Service history</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">
                      {lead.field_what_is_the_service_histor}
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Total services</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">{lead.field_how_many_services}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">
                      Main dealer services
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">{lead.field_how_many_main_dealer}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Last service date</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">
                      {formatDate(lead.field_when_was_the_vehicle_last)}
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">
                      Mileage at last service
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">
                      {lead.field_what_was_the_mileage_at_th}
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <p className="mb-0 font-weight-bold">Cambelt changed</p>
                  </div>
                  <div className="col-6">
                    <p className="mb-0">
                      {lead.field_has_the_cambelt_ever_been}
                    </p>
                  </div>
                </div>
                {lead.relationships.field_scratches_and_damage.length > 0 && (
                  <React.Fragment>
                    <h2 className="mt-5">
                      <FontAwesomeIcon
                        className="mr-2"
                        style={{ color: '#2cabde' }}
                        icon={faCarCrash}
                      />{' '}
                      Scratches & Damage
                    </h2>
                    <div className="row mt-4">
                      {lead.relationships.field_scratches_and_damage.map(
                        item => {
                          return (
                            <div className="col-12 mb-2" key={item.id}>
                              <LazyLoad height={200} offset={100}>
                                <Img
                                  alt=""
                                  className="w-100"
                                  src={`${process.env.BACKEND_URL}${item.uri.url}`}
                                />
                              </LazyLoad>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </React.Fragment>
                )}
                <div className="row mt-4">
                  <div className="d-lg-none d-block col-12">
                    <FeeTable />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

LeadItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query($id: String!) {
    nodeLead(id: { eq: $id }) {
      title
      field_registration_plate
      field_model
      field_year_of_manufacture
      field_mileage
      field_colour
      field_fuel
      field_gearbox
      field_guide_price
      field_alloy_wheel_condition
      field_basic_details
      field_bodywork_condition
      field_car_description
      field_details_on_interior_or_ext
      field_do_you_have_the_book_pack_
      field_do_you_have_the_locking_wh
      field_do_you_have_the_v5c_logboo
      field_engine_capacity
      field_first_registered
      field_has_the_cambelt_ever_been
      field_how_are_they_upholstered
      field_how_many_main_dealer
      field_how_many_services
      field_how_many_working_keys
      field_interior_condition
      field_is_the_v5c_logbook_in_your
      field_is_the_vehicle_on_finance
      field_keepers
      field_last_keeper_change
      field_mot_expiry
      field_seating_plan
      field_tyre_condition
      field_vin
      field_warning_lights_on_dashboar
      field_what_colour_are_the_seats
      field_what_is_the_service_histor
      field_what_is_your_expected_amou
      field_what_was_the_mileage_at_th
      field_when_was_the_vehicle_last
      field_year_of_manufacture
      relationships {
        field_scratches_and_damage {
          id
          uri {
            url
          }
        }
        field_car_location {
          name
        }
        uid {
          id
          relationships {
            roles {
              id
              drupal_internal__id
            }
          }
        }
        field_make {
          drupal_internal__tid
          name
        }
        field_front_driver_side {
          filename
          localFile {
            childImageSharp {
              fixed(height: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_back_driver_side {
          filename
          localFile {
            childImageSharp {
              fixed(height: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_back_passenger_side {
          filename
          localFile {
            childImageSharp {
              fixed(height: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_boot_interior {
          filename
          localFile {
            childImageSharp {
              fixed(height: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_dashboard {
          filename
          localFile {
            childImageSharp {
              fixed(height: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_front_passenger_side {
          filename
          localFile {
            childImageSharp {
              fixed(height: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_front_seats {
          filename
          localFile {
            childImageSharp {
              fixed(height: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_rear_seats {
          filename
          localFile {
            childImageSharp {
              fixed(height: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_wheel_front_driver_side {
          filename
          localFile {
            childImageSharp {
              fixed(height: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_wheel_front_passenger_side {
          filename
          localFile {
            childImageSharp {
              fixed(height: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_wheel_rear_driver_side {
          filename
          localFile {
            childImageSharp {
              fixed(height: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_wheel_rear_passenger_side {
          filename
          localFile {
            childImageSharp {
              fixed(height: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_wheel_rear_passenger_side {
          filename
          localFile {
            childImageSharp {
              fixed(height: 400) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default LeadItem;
